import axios from "axios"

export const client = axios.create({
    baseURL: 'https://api.findmykids.org/api'
})

export default {
    sendAnalytics(data) {
        client.get('/?method=service.funnelTrack&' + new URLSearchParams(data))
    }
}