<template>
  <router-view />
</template>


<script>
import {mapMutations} from "vuex";

export default {
  watch: {
    $route(to) {
      let language = String(to.params.lang);
      if (!language) {
        language = 'en';
      }

      this.$i18n.locale = language
      this.setGetParams(to.query)
      this.setLang(this.$i18n.locale)
    }
  },
  methods: {
    ...mapMutations(['setGetParams', 'setLang']),
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}
#app {
  height: 100vh;
  width: 100vw;
  background-color: #99CEFF;
  justify-content: center;
  display: flex;
}
.btn-block {
  position: fixed;
  bottom: 30px;
  width: 100%;
  max-width: 768px;
}
.btn-wrap {
  display: flex;
  justify-content: center;
}
.btn {
  display: flex;
  height: 56px;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.36px;
  width: 100%;
  margin: auto 32px auto 32px;
  background-color: #0085FF;
  color: white;
}
.disabled {
  background-color: rgba(139, 169, 197, 0.50);
}
.white-btn {
  display: flex;
  padding: 18px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.36px;
  color: #0085FF;
  text-align: center;
  width: 100%;
  margin: auto 32px auto 32px;
}
</style>
