<template>
  <img class="outro-img" alt="" :src="pingoImg"/>
  <div class="outro-content">
    <div class="outro-text">{{ text }}</div>
  </div>
  <div class="btn-block">
    <div class="btn-wrap">
      <button class="btn" @click="$emit('back-to-app')">{{ btnText }}</button>
    </div>
  </div>
</template>

<script>
import pingo from "@/assets/img/outro-pingo.svg"

export default {
  data() {
    return {
      pingoImg: pingo
    }
  },
  name: 'OutroComponent',
  emits: ['back-to-app'],
  props: {
    text: String,
    btnText: String,
  }
}
</script>

<style scoped>
.outro-content {
  margin: 32px 32px auto 32px;
}
.outro-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.36px;
  text-align: center;
  color: #1F252E;
}
.outro-img {
  width: 100%;
}
</style>