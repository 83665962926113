<template>
  <div class="content" v-show="structure">
    <div v-if="isIntro" class="intro-block">
      <IntroComponent
          :title="t[structure.title]"
          :text="t[structure.intro.text]"
          :btn-text="t[structure.intro.btn]"
          :cancel-text="t[structure.intro.cancel]"
          @next-block="nextBlock"
          @cancel="cancel"
      />
    </div>

    <div  v-for="(component, index) in structure.questions" :key="index" v-else-if="isQuestions">
      <div v-if="currentQuestion === index+1">
        <BaseBackPopup @back="prevBlock" v-show="checkIntro()"/>
        <div class="question-block">
          <div class="counter">{{currentQuestion}} / {{ structure.questions.length }}</div>
          <div class="question">
            <div class="question-text">
              {{ t[component.props.question] }}
              <!--    <span v-show="component.required">*</span>-->
            </div>
            <component :is="getComponentType(component.type)"
                       v-model:model-value="answers[index].answer"
                       v-model:open-answer-value="answers[index].openAnswer"
                       v-bind="Object.assign({ t: t }, component.props)"/>
          </div>
        </div>
        <div class="btn-block">
          <div class="btn-wrap">
            <!--              <button class="btn" @click="prevBlock">{{ t[structure.btnBack] }}</button>-->
            <button :disabled="isRequiredQ(index)" v-if="isLastQuestion" :class="isRequiredQ(index) ? 'btn disabled' : 'btn'" @click="sendAnswers">{{ t[structure.btnEnd] }}</button>
            <button :disabled="isRequiredQ(index)" v-else :class="isRequiredQ(index) ? 'btn disabled' : 'btn'" @click="nextBlock">{{ t[structure.btnNext] }}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="outro-block">
      <OutroComponent
          :text="t[structure.outro.text]"
          :btn-text="t[structure.outro.btn]"
          @back-to-app="backToApp"
      />
    </div>
  </div>
</template>

<script>
import OpenQuestion from "@/components/schemas/OpenQuestion.vue";
import CloseQuestion from "@/components/schemas/CloseQuestion.vue";
import IntroComponent from "@/components/ui/IntroComponent.vue";
import OutroComponent from "@/components/ui/OutroComponent.vue";
import {mapActions} from "vuex";
import BaseBackPopup from "@/components/ui/base/BaseBackPopup.vue";
import {closeSurvey} from "@/utils/utils";
export default {
  name: 'SurveyBuilder',
  components: {
    BaseBackPopup,
    OpenQuestion,
    CloseQuestion,
    IntroComponent,
    OutroComponent
  },
  data() {
    return {
      currentQuestion: 0,
      answers: [],
    }
  },
  props: {
    structure: {
      type: Object
    },
    t: {
      type: Object
    }
  },
  mounted() {
    this.funnelTrack({
      action: this.structure.analytics.openSurvey,
      addData: { surveyName: this.structure.analytics.name }
    }).finally(() => {
      if (this.structure.intro.skip) {
        this.nextBlock()
      }
      this.structure.questions.forEach((q) => {
        this.answers.push({question: this.t[q.props.question], answer: [], openAnswer: ''})
      })
    })
  },
  computed: {
    isIntro () {
      return this.currentQuestion === 0 && !this.structure.intro.skip
    },
    isQuestions () {
      return this.currentQuestion > 0 && this.currentQuestion <= this.structure.questions.length
    },
    isLastQuestion () {
      return this.currentQuestion === this.structure.questions.length
    }
  },
  methods: {
    ...mapActions(['funnelTrack']),
    checkIntro() {
      return !(this.currentQuestion === 1 && this.structure.intro.skip)
    },
    isRequiredQ(index) {
      return !!(this.structure.questions[index].required && (!this.answers[index].answer || this.answers[index].answer.length === 0 && !this.answers[index].openAnswer))
    },
    cancel () {
      this.funnelTrack({
        action: this.structure.analytics.closeSurvey,
        addData: { surveyName: this.structure.analytics.name, backUrl: this.structure.outro.backUrl }
      }).finally(() => {
        closeSurvey(this.structure.intro.backUrl)
      })
    },
    nextBlock () {
      this.currentQuestion++
      window.scrollTo(0,0)
    },
    prevBlock () {
      this.currentQuestion--
      window.scrollTo(0,0)
    },
    sendAnswers () {
      this.funnelTrack({
        action: this.structure.analytics.sendAnswers,
        addData: { surveyName: this.structure.analytics.name, answers: this.answers }
      }).finally(() => {
        this.nextBlock()
      })
    },
    getComponentType(type) {
      return type + 'Question'
    },
    backToApp() {
      this.funnelTrack({
        action: this.structure.analytics.closeSurvey,
        addData: { surveyName: this.structure.analytics.name, backUrl: this.structure.outro.backUrl }
      }).finally(() => {
        closeSurvey(this.structure.outro.backUrl)
      })
    }
  }
}
</script>

<style scoped>
.content {
  height: 100vh;
  background-color: white;
  max-width: 768px;
  width: 100%;
  position: relative;
}
.intro-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.question-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto 32px auto 32px;
}
.question {
  margin-top: 12px;
}
.counter {
  margin-top: 56px;
  color: #4A5464;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.16px;
}
.question-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.36px;
  color: #1F252E;
}
.outro-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
