<template>
  <img class="intro-img" alt="" :src="pingoImg"/>
  <div class="intro-content">
    <div class="intro-title">{{ title }}</div>
    <div class="intro-text">{{ text }}</div>
  </div>
  <div class="btn-block">
    <div class="btn-wrap">
      <button class="btn" @click="$emit('next-block')">{{ btnText }}</button>
    </div>
    <div class="btn-wrap" v-show="cancelText && isWebView()">
      <button class="white-btn" @click="$emit('cancel')">{{ cancelText }}</button>
    </div>
  </div>
</template>

<script>
import pingo from "@/assets/img/pingo.svg"
import {isWebView} from "@/utils/utils";

export default {
  methods: {
    isWebView() {
      return isWebView
    }
  },
  data() {
    return {
      pingoImg: pingo
    }
  },
  name: 'IntroComponent',
  emits: ['next-block', 'cancel'],
  props: {
    title: String,
    text: String,
    btnText: String,
    cancelText: String
  }
}
</script>

<style scoped>
.intro-content {
  margin: 32px 32px auto 32px;
}
.intro-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.36px;
  text-align: center;
  color: #1F252E;
}
.intro-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.16px;
  text-align: center;
  color: #4A5464;
  margin-top: 20px;
}
.intro-img {
  width: 100%;
}
</style>